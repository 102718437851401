var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent mx-auto mb-4",attrs:{"flat":"","width":"1440"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',{staticClass:"transparent pb-12 px-12 mt-8 mb-12",attrs:{"flat":"","min-width":"1280"}},[_c('v-data-table',{staticStyle:{"background":"transparent !important"},attrs:{"headers":_vm.headers,"items":_vm.localCustomers,"item-key":"index","page":_vm.page,"fixed-header":"","footer-props":{
          itemsPerPage: _vm.rowsPerPage,
          itemsPerPageOptions: [10, 20, 50, 100, -1],
        },"items-per-page":_vm.rowsPerPage,"single-expand":"","expanded":_vm.expanded},on:{"update:page":function($event){_vm.page=$event},"pagination":_vm.pagination,"update:itemsPerPage":function($event){_vm.rowsPerPage=$event},"update:items-per-page":function($event){_vm.rowsPerPage=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.expand},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"#004","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.cloneCustomer(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"#004"}},[_vm._v(" mdi-layers-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Clone customer")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeCustomer(item)}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-delete ")])],1),_c('v-icon',{staticClass:"ml-4",attrs:{"color":item.commercial ? '#f50' : '#09b'}},[_vm._v(" "+_vm._s(_vm.getIcon(item))+" ")])]}},{key:"item.customerName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCustomerName(item))+" ")]}},(!_vm.partnerId)?{key:"item.partnerName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPartnerName(item))+" ")]}}:null,{key:"item.services",fn:function(ref){
        var item = ref.item;
return _vm._l((_vm.getServiceNames(item)),function(name,num){return _c('div',{key:num},[_vm._v(" "+_vm._s(name)+" ")])})}},{key:"footer.prepend",fn:function(){return [_c('v-text-field',{staticStyle:{"display":"inline-block","max-width":"360px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('span',{staticClass:"mx-4"},[_vm._v("Total customers: "+_vm._s(_vm.selectedCustomersNumber))]),_c('div',{staticClass:"ml-10 mr-4"},[_c('v-icon',{attrs:{"small":"","color":"#d00"}},[_vm._v(" mdi-bell-alert ")]),_vm._v(" "+_vm._s(_vm.fatalErrors)+" ")],1),_c('div',{staticClass:"mx-4"},[_c('v-icon',{attrs:{"small":"","color":"#900"}},[_vm._v(" mdi-alert-outline ")]),_vm._v(" "+_vm._s(_vm.errors)+" ")],1),_c('div',{staticClass:"mx-4"},[_c('v-icon',{attrs:{"small":"","color":"#f50"}},[_vm._v(" mdi-message-alert ")]),_vm._v(" "+_vm._s(_vm.warnings)+" ")],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('EditImportedCustomer',{attrs:{"partnerId":_vm.resellerId,"index":item.index}})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }